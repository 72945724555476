import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import "./style.css"

const Logo = () => (
  <StaticImage
    className="bio-avatar"
  layout="fixed"
  formats={["auto", "webp", "avif"]}
  src="../../../../static/profile-pic.jpg"
  width={50}
  height={50}
  quality={85}
  alt="Profile picture"
/>

)

export default Logo
